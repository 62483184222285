import { Flex, Text, VStack } from '@chakra-ui/react'

import { DefaultReferralFormView } from '../default/DefaultReferralFormView'

import { ChasFormClinicInfo } from './components/ChasFormClinicInfo'
import { ChasFormDoctorAcknowledgement } from './components/ChasFormDoctorAcknowledgement'
import { ChasFormHeader } from './components/ChasFormHeader'
import { ChasFormPatientInfo } from './components/ChasFormPatientInfo'
import { ChasFormReferralInfo } from './components/ChasFormReferralInfo'
import { ChasPageLayout } from './components/ChasPageLayout'
import {
  extractChasClinicInfo,
  extractChasPatientInfo,
  extractChasReferralInfo,
} from './extract-chas-form-fields'

import { PublicReferralDto } from '~shared/dtos'

export const ChasReferralFormView = ({
  referral,
}: {
  referral: PublicReferralDto
}) => {
  const patientData = extractChasPatientInfo(referral)
  const referralData = extractChasReferralInfo(referral)
  const clinicData = extractChasClinicInfo(referral)

  if (!referral.isSubsidised) {
    /* TODO: @thamsimun Add the form variant in the referral entity instead (now we are relying on the query 
      param which means PHAS needs to know the logic but this shouldnt be the case). current behaviour still
      show variant=chas on url when its not subsidised */
    return <DefaultReferralFormView referral={referral} />
  }

  return (
    <Flex py={4} mx="auto">
      <VStack alignItems="center" justifyContent="center" flexShrink={0}>
        <ChasPageLayout referralId={referral.id}>
          <ChasFormHeader />
          <Flex
            flexDir="column"
            alignItems="stretch"
            borderRight="1px"
            borderBottom="1px"
          >
            <ChasFormPatientInfo {...patientData} />
            <ChasFormReferralInfo {...referralData} />
          </Flex>
        </ChasPageLayout>

        <ChasPageLayout referralId={referral.id}>
          <Flex
            flexDir="column"
            alignItems="stretch"
            borderRight="1px"
            borderBottom="1px"
            style={{
              breakInside: 'avoid',
              breakBefore: 'always',
            }}
          >
            <ChasFormClinicInfo {...clinicData} />
            <ChasFormDoctorAcknowledgement
              mcr={clinicData.doctor.mcr}
              name={clinicData.doctor.name}
              date={referralData.referralDate}
            />
          </Flex>
          <Text fontSize="sm" alignSelf="start">
            <i>
              You may be contacted by the Referred Institution or Provider for
              verification if the mandatory fields are not populated.
            </i>
          </Text>
          <Text fontSize="sm" fontWeight="bold" alignSelf="start">
            <i>
              Please remind patients to bring this referral form with their
              CHAS/PG/MG/PA card & NRIC for verification during their
              appointment.
            </i>
          </Text>
        </ChasPageLayout>
      </VStack>
    </Flex>
  )
}
