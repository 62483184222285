import { BiFile } from 'react-icons/bi'
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'

import { PublicReferralDto } from '~shared/dtos'

export const ReferralRequest = ({
  referral,
}: {
  referral: PublicReferralDto
}) => {
  return (
    <Flex alignItems="flex-start" gap={4} flexDir="column">
      <Text
        textStyle="body-2"
        fontWeight="600"
        letterSpacing="1px"
        color="base.content.default"
      >
        REFERRAL FORM
      </Text>
      {referral.formResponses.map(({ question, answer, id }) => (
        <Box position="relative" w="full" key={id}>
          <VStack w="full" spacing={0} align="left">
            <Text
              w="full"
              textColor="interaction.support.placeholder"
              textStyle="legal"
              fontSize="0.625rem"
              fontWeight="semibold"
              isTruncated={true}
              mr="8px"
            >
              {question}
            </Text>
            {(() => {
              if (
                typeof answer === 'string' &&
                referral.attachments?.some(
                  (attachment) => attachment.name === answer,
                )
              ) {
                return (
                  <HStack
                    w="full"
                    borderWidth="1px"
                    borderRadius="md"
                    borderColor="base.divider.strong"
                    backgroundColor="base.canvas.backdrop"
                    marginTop={1}
                    textStyle="body-2"
                    fontWeight="medium"
                    p={1}
                    display="flex"
                    alignItems="flex-start"
                  >
                    <BiFile style={{ marginTop: '4px' }} />
                    <Text
                      as="a"
                      href={
                        referral.attachments?.find(
                          (attachment) => attachment.name === answer,
                        )?.url
                      }
                      color="base.content.strong"
                      target="_blank"
                      noOfLines={2}
                      flex={1}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      wordBreak="break-word"
                    >
                      {answer}
                    </Text>
                  </HStack>
                )
              }

              return typeof answer === 'string' ||
                typeof answer === 'number' ? (
                <Text textStyle="body-2" whiteSpace="break-spaces">
                  {answer}
                </Text>
              ) : (
                answer.map((row) => (
                  <Text key={row} textStyle="body-2" whiteSpace="break-spaces">
                    {row}
                  </Text>
                ))
              )
            })()}
          </VStack>
        </Box>
      ))}
    </Flex>
  )
}
