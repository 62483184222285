import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/query-keys'
import { ApiService } from '@/services'
import { ReferralDto } from '~shared/dtos'

export const useGetReferral = ({
  referralId,
  token,
}: {
  referralId: string
  token: string
}) => {
  const getReferral = async () => {
    const searchParams = {
      includeAttachments: true,
    }
    return await ApiService.extend({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .get(`v1/referrals/${referralId}`, {
        searchParams,
      })
      .json<ReferralDto>()
  }

  return useQuery({
    queryKey: queryKeys.referral(referralId),
    queryFn: getReferral,
    retry: (failureCount, error) => {
      if (
        error instanceof Error &&
        'response' in error &&
        (error.response as Response).status === 401
      ) {
        return false
      }
      return failureCount < 3
    },
  })
}
