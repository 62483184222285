import { Flex } from '@chakra-ui/react'

import { AcknowledgeForm } from './AcknowledgeForm'

import { PublicReferralViewFactory } from '@/features/public-referral/PublicReferralViewFactory'
import { ReferralDto } from '~shared/dtos'

export const Acknowledge = ({ referral }: { referral: ReferralDto }) => {
  return (
    <Flex direction="row" w="full" h="100vh">
      <PublicReferralViewFactory referral={referral} />
      <Flex w="400px" overflowY="auto" p="1rem" pb="2rem" marginLeft="auto">
        <AcknowledgeForm referral={referral} w="full" alignItems="stretch" />
      </Flex>
    </Flex>
  )
}
