import { useEffect, useRef } from 'react'

export const usePromptPrintDialogue = ({ enabled }: { enabled: boolean }) => {
  const hasPrintedRef = useRef(false)

  useEffect(() => {
    if (enabled && !hasPrintedRef.current) {
      // memorize this to prevent the window pop again immediately when close in the new page
      hasPrintedRef.current = true
      window.print()
    }
  }, [enabled])
}
