import { Heading, Image, Link, Text } from '@chakra-ui/react'

import PaisehDoctorSvg from 'assets/paiseh-doctor.svg'

import { PlainAcknowledgeLayout } from './PlainAcknowledgeLayout'

import { HAS_SUPPORT_EMAIL } from '~shared/team.constants'

export const NotYoursToAcknowledge = () => {
  const href = `mailto:${HAS_SUPPORT_EMAIL}`

  return (
    <PlainAcknowledgeLayout>
      <Image src={PaisehDoctorSvg} boxSize="150px" mx="auto" />
      <Heading fontSize="24px" color="base.content.strong" textAlign="center">
        {`This referral has been directed elsewhere`}
      </Heading>
      <Text color="base.content.default" textAlign="center">
        The sender may have withdrawn or amended the referral. No further action
        is required from you. If you think this is an error, please write in to{' '}
        <Link href={href}>{HAS_SUPPORT_EMAIL}</Link>
      </Text>
    </PlainAcknowledgeLayout>
  )
}
