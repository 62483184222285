import ky, { HTTPError } from 'ky'

export const API_BASE_URL = '/api'

export const ApiService = ky.create({ prefixUrl: API_BASE_URL })
const FALLBACK_ERROR_MESSAGE = 'Something went wrong'

export const isApiError = (error: unknown): error is HTTPError => {
  const apiError = error as HTTPError
  return !!apiError.response.status
}

export const getApiErrorMessage = (
  error: unknown,
  options = { fallbackMessage: FALLBACK_ERROR_MESSAGE },
): string => {
  if (isApiError(error)) {
    return error.message
  }
  if (error instanceof Error && error.message) {
    return error.message
  }
  return options.fallbackMessage
}
