import { NhgdCodeCategories } from './phas-form-fields.types'

const footnotes: { [key: number]: string } = {
  1: 'Preparation required. Ultrasounds are only available for patients 16yo and above.',
  2: 'Doppler scans are available only at NHGD Ang Mo Kio - Thye Hua Kuan Hospital.',
}

export const NHGD_RADIOLOGY_TEST_CODES: NhgdCodeCategories = {
  headAndNeck: [
    { code: '500', name: 'Facial Bones' },
    { code: '501', name: 'Nasal Bone - Lateral Only' },
    { code: '503', name: 'Neck, Lateral Only' },
    { code: '505', name: 'Mandibles' },
    { code: '506', name: 'Mastoids' },
    { code: '507', name: 'Orbits' },
    { code: '509', name: 'Sinuses - Paranasal' },
    { code: '510', name: 'Skull (AP & Lateral)' },
    { code: '511', name: 'Temporo-Mandibular Joints' },
    { code: '512', name: 'Cervical Spine - AP & LAT' },
    { code: '513', name: 'Cervical Spine - Obliques' },
    { code: '514', name: 'Cervical Spine - Open Mouth' },
    { code: '515', name: 'Cervical Spine - Flex & Ext' },
  ],
  upperLimbs: [
    { code: '585', name: 'Acromio-clavicular Joints' },
    { code: '586', name: 'Sterno-clavicular Joints' },
    { code: '517-R', name: 'Clavicle (Right)' },
    { code: '517-L', name: 'Clavicle (Left)' },
    { code: '518', name: 'Clavicles (Both)' },
    { code: '519-R', name: 'Fingers (Right)' },
    { code: '519-L', name: 'Fingers (Left)' },
    { code: '520-R', name: 'Hand (Right)' },
    { code: '520-L', name: 'Hand (Left)' },
    { code: '521', name: 'Hands (Both)' },
    { code: '575', name: 'Hand (Bone age)' },
    { code: '522-R', name: 'Humerus (Right)' },
    { code: '522-L', name: 'Humerus (Left)' },
    { code: '523', name: 'Humeri (Both)' },
    { code: '524-R', name: 'Radius and Ulna (Right)' },
    { code: '524-L', name: 'Radius and Ulna (Left)' },
    { code: '525', name: 'Radius and Ulna (Both)' },
    { code: '526-R', name: 'Elbow (Right)' },
    { code: '526-L', name: 'Elbow (Left)' },
    { code: '527', name: 'Elbows (Both)' },
    { code: '528-R', name: 'Shoulder (Right)' },
    { code: '528-L', name: 'Shoulder (Left)' },
    { code: '530', name: 'Shoulders (Both)' },
    { code: '529-R', name: 'Scapula (Right)' },
    { code: '529-L', name: 'Scapula (Left)' },
    { code: '589', name: 'Scapulae (Both)' },
    { code: '595-L', name: 'Shoulder - Axial (Left)' },
    { code: '595-R', name: 'Shoulder - Axial (Right)' },
    { code: '597', name: 'Shoulder - Axial (Both)' },
    { code: '531-R', name: 'Wrist (Right)' },
    { code: '531-L', name: 'Wrist (Left)' },
    { code: '532', name: 'Wrists (Both)' },
    { code: '533-R', name: 'Scaphoid (Right)' },
    { code: '533-L', name: 'Scaphoid (Left)' },
    { code: '593', name: 'Scaphoid (Both)' },
  ],
  lowerLimbs: [
    { code: '534-R', name: 'Ankle (Right)' },
    { code: '534-L', name: 'Ankle (Left)' },
    { code: '535', name: 'Ankles (Both)' },
    { code: '536-R', name: 'Femur (Right)' },
    { code: '536-L', name: 'Femur (Left)' },
    { code: '537', name: 'Femurs (Both)' },
    { code: '538-R', name: 'Foot (Right)' },
    { code: '538-L', name: 'Foot (Left)' },
    { code: '539', name: 'Feet (Both)' },
    { code: '540-R', name: 'Toes (Right)' },
    { code: '540-L', name: 'Toes (Left)' },
    { code: '541-R', name: 'Calcaneum (Right)' },
    { code: '541-L', name: 'Calcaneum (Left)' },
    { code: '542', name: 'Calcanei (Both)' },
    { code: '543', name: 'Calcanei - Lateral only (Both)' },
    { code: '544-R', name: 'Hip (Right)' },
    { code: '544-L', name: 'Hip (Left)' },
    { code: '545', name: 'Hips (Both)' },
    { code: '546-R', name: 'Knee - Supine (Right)' },
    { code: '546-L', name: 'Knee - Supine (Left)' },
    { code: '547', name: 'Knees - Supine (Both)' },
    { code: '548L', name: 'Knee - Skyline Only (Left)' },
    { code: '548R', name: 'Knee - Skyline Only (Right)' },
    { code: '594', name: 'Knee - Skyline Only (Both)' },
    { code: '546+549L', name: 'Knee - Standing (Left)' },
    { code: '546+549R', name: 'Knee - Standing (Right)' },
    { code: '598', name: 'Knees - Standing (Both)' },
    { code: '550-R', name: 'Tibia and Fibula (Right)' },
    { code: '550-L', name: 'Tibia and Fibula (Left)' },
    { code: '551', name: 'Tibia and Fibula (Both)' },
  ],
  trunk: [
    { code: '552', name: 'Abdomen / KUB - Supine' },
    { code: '553', name: 'Abdomen - Erect / Decubitus' },
    { code: '555', name: 'Pelvis' },
    { code: '556', name: 'Chest - PA/AP (Report only)' },
    { code: '557-R', name: 'Chest - PA & Lateral (Right)' },
    { code: '557-L', name: 'Chest - PA & Lateral (Left)' },
    { code: '588-R', name: 'Chest - Lateral (Right)' },
    { code: '588-L', name: 'Chest - Lateral (Left)' },
    { code: '587-R', name: 'Chest - Oblique (Right)' },
    { code: '587-L', name: 'Chest - Oblique (Left)' },
    { code: '559', name: 'Chest - Apical' },
    { code: '590-R', name: 'Ribs (Right)' },
    { code: '590-L', name: 'Ribs (Left)' },
    { code: '560', name: 'Sacro - iliac Joints' },
    { code: '561', name: 'Sternum' },
    { code: '562', name: 'Thoracic Spine - AP & LAT (Supine)' },
    { code: '567', name: 'Lumbosacral Spine - Flex&Ext (Supine)' },
    { code: '568', name: 'Lumbosacral Spine - AP&LAT (Supine)' },
    { code: '569', name: 'Lumbosacral Spine - Obliques (Supine)' },
    { code: '570', name: 'Sacrum' },
    { code: '571', name: 'Coccyx' },
  ],
  ultrasound: [
    {
      code: '600',
      name: 'Liver / Hepatobiliary System',
      description: footnotes[1],
    },
    {
      code: '601',
      name: 'Kidneys',
      description: [
        footnotes[1],
        'This test does not include renal artery doppler.',
      ].join(' '),
    },
    {
      code: '602',
      name: 'Pelvis',
      description: [
        footnotes[1],
        'This test is to assess organs and structures within the female pelvis.',
      ].join(' '),
    },
    {
      code: '603',
      name: 'Abdomen (Liver & Kidneys)',
      description: footnotes[1],
    },
    {
      code: '606-L',
      name: 'Doppler Leg DVT (Left)',
      description: [
        footnotes[2],
        footnotes[1],
        'This test is specifically for detecting Deep Vein Thrombosis (DVT) and does not assess venous insufficiency and varicosities.',
      ].join(' '),
    },
    {
      code: '606-R',
      name: 'Doppler Leg DVT (Right)',
      description: [
        footnotes[2],
        footnotes[1],
        'This test is specifically for detecting Deep Vein Thrombosis (DVT) and does not assess venous insufficiency and varicosities.',
      ].join(' '),
    },
    {
      code: '607',
      name: 'Doppler Leg DVT (Both legs)',
      description: [
        footnotes[2],
        footnotes[1],
        'This test is specifically for detecting Deep Vein Thrombosis (DVT) and does not assess venous insufficiency and varicosities.',
      ].join(' '),
    },
    {
      code: '610',
      name: 'Thyroid',
      description: footnotes[1],
    },
    {
      code: '612',
      name: 'Soft Tissue',
      description: [
        footnotes[2],
        footnotes[1],
        'This test is to examine the soft tissues surrounding lumps and bumps and other swellings on the body.',
      ].join(' '),
    },
  ],
  mammogram: [
    {
      code: '572',
      name: 'Mammogram, Non-BSS',
      description: footnotes[1],
    },
    {
      code: '578',
      name: 'Mammogram, BSS (Singaporean)',
      description: footnotes[1],
    },
    {
      code: '578PR',
      name: 'Mammogram, BSS (PR)',
      description: footnotes[1],
    },
  ],
  bmd: [{ code: '900', name: 'BMD' }],
  addons: [
    { code: '573', name: 'Additional View' },
    { code: '996', name: 'Mammo film printing (per set)' },
    { code: '997', name: 'Film printing (per film)' },
    { code: '998', name: 'Copy of report' },
    { code: '999', name: 'CD printing cost (per CD)' },
  ],
}
