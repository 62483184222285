import { Box, Flex, HStack, Text } from '@chakra-ui/react'

import { bodyStyle, borders, headerStyle } from '../chas-layer-styles'
import { ChasFormPatientDetails } from '../extract-chas-form-fields'

import { DATE_DISPLAY_FORMATS, formatDate } from '@/utils/human-readable'
import {
  ClinicEnrolmentStatus,
  HealthierSGEnrolmentStatus,
} from '~shared/types'

export const ChasFormPatientInfo = (info: ChasFormPatientDetails) => {
  return (
    <Flex flexDir="column">
      <Box {...borders} {...headerStyle}>
        Part I: Patient Information
      </Box>
      <Flex flexDir="row">
        <Flex flexDir="row" width="60%" {...bodyStyle}>
          <Box {...borders} px={1} py={0} width="40%">
            <b>{`Name`}</b>
          </Box>
          <Box {...borders} px={1} py={0} flex={1}>
            {info.name}
          </Box>
        </Flex>
        <Flex flexDir="row" width="40%" {...bodyStyle}>
          <Box {...borders} px={1} py={0} width="40%">
            <b>{`Gender (optional)`}</b>
          </Box>
          <HStack {...borders} px={1} py={0} flex={1}>
            <HStack>
              <input
                type="checkbox"
                readOnly
                checked={info.gender === 'Male'}
                style={{ filter: 'grayscale(100%)' }}
              />
              <Text>Male</Text>
            </HStack>
            <HStack>
              <input
                type="checkbox"
                readOnly
                checked={info.gender === 'Female'}
                style={{ filter: 'grayscale(100%)' }}
              />
              <Text>Female</Text>
            </HStack>
          </HStack>
        </Flex>
      </Flex>
      <Flex>
        <Flex flexDir="row" width="60%" {...bodyStyle}>
          <Box {...borders} px={1} py={0} width="40%">
            <b>{`NRIC / FIN No`}</b>
          </Box>
          <Box {...borders} px={1} py={0} flex={1}>
            {info.uin}
          </Box>
        </Flex>
        <Flex flexDir="row" width="40%" {...bodyStyle}>
          <Box {...borders} px={1} py={0} width="40%">
            <b>{`Race (optional)`}</b>
          </Box>
          <Box {...borders} px={1} py={0} flex={1}></Box>
        </Flex>
      </Flex>
      <Flex>
        <Flex flexDir="row" width="60%" {...bodyStyle}>
          <Box {...borders} px={1} py={0} width="40%">
            <b>{`Date of Birth (optional)`}</b>
          </Box>
          <Box {...borders} px={1} py={0} flex={1}>
            {info.dob &&
              formatDate({
                date: info.dob,
                format: DATE_DISPLAY_FORMATS.DATE_ONLY,
              })}
          </Box>
        </Flex>
        <Flex flexDir="row" width="40%" {...bodyStyle}>
          <Box {...borders} px={1} py={0} width="40%">
            <b>{`Contact No.`}</b>
          </Box>
          <Box {...borders} px={1} py={0} flex={1}>
            {info.phoneNumber}
          </Box>
        </Flex>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Email (optional)`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}></Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Address (optional)`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}></Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Card Type`}</b> <br />
          <i>(Select all that are applicable)</i>
        </Box>
        <HStack {...borders} px={1} py={0} flex={1} flexWrap="wrap" rowGap={0}>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.chasStatus === 'merdeka'}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>MG</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.chasStatus === 'pioneer'}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>PG</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.chasStatus === 'none'}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>PA</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.chasStatus === 'blue'}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>CHAS - Blue</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.chasStatus === 'orange'}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>CHAS - Orange</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.chasStatus === 'green'}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>CHAS - Green</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={false}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>None of the above</Text>
          </HStack>
        </HStack>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Healthier SG (HSG) Enrolment Status`}</b>
        </Box>
        <HStack {...borders} px={1} py={0} flex={1} flexWrap="wrap">
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={
                info.healthierSGEnrolmentStatus ===
                HealthierSGEnrolmentStatus.Enrolled
              }
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>Enrolled</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={
                info.healthierSGEnrolmentStatus ===
                HealthierSGEnrolmentStatus.NotEnrolled
              }
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>Not Enrolled</Text>
          </HStack>
        </HStack>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Named patient is enrolled to my clinic `}</b>
          <i>(If applicable)</i>
        </Box>
        <HStack {...borders} px={1} py={0} flex={1} flexWrap="wrap">
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.clinicEnrolmentStatus === ClinicEnrolmentStatus.Yes}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>Yes</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.clinicEnrolmentStatus === ClinicEnrolmentStatus.No}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>No</Text>
          </HStack>
        </HStack>
      </Flex>
    </Flex>
  )
}
