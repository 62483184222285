import { useParams, useSearchParams } from 'react-router-dom'

import { Acknowledge } from './components/Acknowledge/Acknowledge'
import { ErrorInAcknowledgement } from './components/ErrorInAcknowledgement'
import { NotYoursToAcknowledge } from './components/NotYoursToAcknowledge'

import { LoadingScreen } from '@/components/LoadingScreen'
import { useGetReferral } from '@/hooks/useGetReferral'
import { useNamedDocumentTitle } from '@/hooks/useNamedDocumentTitle'
import { isApiError } from '@/services'

export const EmailAckPage = () => {
  const { referralId } = useParams()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('auth') ?? ''

  const {
    data: referral,
    isPending,
    isError,
    error,
  } = useGetReferral({ referralId: referralId ?? '', token })

  useNamedDocumentTitle(referral)

  if (isPending) return <LoadingScreen />
  if (isError) {
    if (isApiError(error) && error.response.status === 401) {
      return <NotYoursToAcknowledge />
    }
    return <ErrorInAcknowledgement />
  }

  return <Acknowledge referral={referral} />
}
